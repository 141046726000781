export const fleetStatusApiQuery = `
    {
      fleetStatus(input: {
        extMfgrSerialNums: [],
        customerIds: [],
        vehicleSensorBoxActive: true,
        recordedAfterVehicleSensorBoxPaired: true,
        customerVehicleActive: true,
        useSensorBoxCaches: false,
        activeFaults: true
      }) {
        trailers {
          customerId
          customerName
          vehicleId
          sensorBoxMfgrId
          vin
          unitId
          vehicleTypeShortName
          hasUltrasonicCargoSensor
          iccid
          cargoCameraDeviceSerialNumber
          installedSensorTypeIdsList
          thermoKingExternalId
          extMfgrSerialNum
          deviceType {
            id
            description
          }
          gps {
            lat
            lng
            basedHeading
          }
          enhancedWeight {
            isLoaded
            restingBogieWeightLbs
          }
          odometer {
            miles
          }
          abs {
            activeCount
            samples {
              absFaultLevelId
              absSeverityLevelId
            }
          }
          lights {
            activeCount
          }
          tether {
            tethered
            liveUntetheredDurationMs
          }
          door {
            open
          }
          tis {
            description
          }
          gpsOdometer {
            miles
          }
        }
      }
    }
  `;
