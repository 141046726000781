/* eslint-disable */
import _get from 'lodash/get';
import _flow from 'lodash/flow';
import _flatMap from 'lodash/flatMap';
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';

import { handleErrors } from '../utils/errorUtils';

import { getFilterCookies, setFaultTotals } from './dashboardFilters';

import {
  FETCH_TRACKING_DASHBOARD_DATA_FAIL,
  RECEIVE_TRACKING_DASHBOARD_DATA_REFRESH,
  SEARCH_TRUCKS,
  TOGGLE_TRACKING_DASHBOARD_DETAILS_MODAL,
  TOGGLE_TRACKING_DASHBOARD_FILTER_VIEW,
  TOGGLE_TRACKING_DASHBOARD_LOADING,
  TOGGLE_LIST_VIEW,
  TOGGLE_SHOW_SHAPES_VIEW,
  CREATE_NEW_SHAPE,
  TOGGLE_ALERTS_STATE,
  APPLY_FILTER,
  TOGGLE_USER_PANEL,
  CHANGE_FILTER,
  CHANGE_WEIGHT_FILTER,
  SHOW_SPECS_MENU,
  TOGGLE_TRAFFIC_OVERLAY,
  TOGGLE_WEATHER_CONDITIONS,
  SHOW_COLLAPSE,
  SHOW_PREVENTATIVE_COLLAPSE,
  SHOW_PREVENTATIVE,
  HIDE_MODAL_COLLAPSES,
  SET_INITIAL_VIN_VALUE,
  TOGGLE_SENSOR_DETAILS_SCREEN,
  OPEN_NAVIGATION_DRAWER,
  CLOSE_NAVIGATION_DRAWER,
  FILTER_TRUCKS,
  FETCH_ALL_FLEET_TRAILERS_BEGIN,
  FETCH_ALL_FLEET_TRAILERS_SUCCESS,
  FETCH_ALL_FLEET_TRAILERS_FAIL,
} from '../constants';
import { fleetStatusApiQuery } from '../api/fleetStatusApi';
import { doQuery } from '../utils/fetch';
import { hasCautionAbsSeverityLevel, hasCriticalAbsSeverityLevel, FAULT_LEVELS } from '../constants/enums/absFaults';
import { mapTrailersQuery } from '../api/mapTrailersApi';
import { trailerHasAbsInstalled, trailerHasTisInstalled } from '../utils/mapFilteringUtils';
import { TRAILER_BATTERY_STATUS } from '../constants/enums/trailerBattery';
import Cookies from 'js-cookie';
import { trailerHasLightFaultInstalled } from '../utils/mapFilteringUtils';

export const searchTrucks = (keyword, selectedFilters, selectedCustomer, selectedVins) => ({
  type: SEARCH_TRUCKS,
  keyword,
  selectedFilters,
  selectedCustomer,
  selectedVins,
});
export const toggleModal = trailer => ({
  type: TOGGLE_TRACKING_DASHBOARD_DETAILS_MODAL,
  trailer,
  vinNumber: trailer.vinNumber,
});
export const toggleFilterView = () => ({
  type: TOGGLE_TRACKING_DASHBOARD_FILTER_VIEW,
});

export const toggleGdServiceCenters = () => ({
  type: 'TOGGLE_GD_SERVICE_CENTERS',
});

export const toggleGeofence = () => ({
  type: 'TOGGLE_GEOFENCE',
});

export const toggleShowShapesView = () => ({
  type: TOGGLE_SHOW_SHAPES_VIEW,
});

export const createNewShape = payload => ({
  type: CREATE_NEW_SHAPE,
  payload,
});

export const toggleTrafficOverlay = () => ({
  type: TOGGLE_TRAFFIC_OVERLAY,
});

export const toggleWeatherConditions = () => ({
  type: TOGGLE_WEATHER_CONDITIONS,
});

export const toggleListView = () => ({
  type: TOGGLE_LIST_VIEW,
});

export const toggleAlertsState = () => ({
  type: TOGGLE_ALERTS_STATE,
});

export const applyFilter = filters => ({
  type: APPLY_FILTER,
  filters,
});
export const changeFilter = filters => ({ type: CHANGE_FILTER, filters });
export const changeWeightFilter = data => ({
  type: CHANGE_WEIGHT_FILTER,
  data,
});

export const toggleUserPanel = () => ({
  type: TOGGLE_USER_PANEL,
});

export const showSpecsMenu = () => ({ type: SHOW_SPECS_MENU });
export const openCollapse = () => ({ type: SHOW_COLLAPSE });
export const openPreventativeCollapse = () => ({
  type: SHOW_PREVENTATIVE_COLLAPSE,
});
export const openPreventative = () => ({ type: SHOW_PREVENTATIVE });
export const hideModalCollapses = () => ({ type: HIDE_MODAL_COLLAPSES });

export const setInitialVinValue = vin => ({
  type: SET_INITIAL_VIN_VALUE,
  vin,
});

export const getFaultTotals = trackingDashboardData => {
  let absNoFaultsCount = 0;
  let absFaultsCount = 0;
  let absAlertsCount = 0;
  let tisNoFaultsCount = 0;
  let tisFaultsCount = 0;
  let tisAlertsCount = 0;
  let batteryNoFaultsCount = 0;
  let batteryFaultsCount = 0;
  let batteryAlertsCount = 0;
  let lightsNoFaultsCount = 0;
  let lightsFaultsCount = 0;
  let lightsAlertsCount = 0;

  const countAbsFaults = vehicle => {
    const absSeverityLevelCritical = hasCriticalAbsSeverityLevel(vehicle?.absSeverityLevelId);
    const absSeverityLevelCaution = hasCautionAbsSeverityLevel(vehicle?.absSeverityLevelId);
    if (absSeverityLevelCritical) {
      absFaultsCount++;
    }
    if (absSeverityLevelCaution) {
      absAlertsCount++;
    }
  };

  trackingDashboardData.forEach(vehicle => {
    if (trailerHasAbsInstalled(vehicle)) {
      vehicle?.absSeverityLevelId == FAULT_LEVELS.ABS.faultLevelId || vehicle?.absSeverityLevelId == FAULT_LEVELS.COMPONENT.faultLevelId
        ? countAbsFaults(vehicle)
        : absNoFaultsCount++;
    }

    if (trailerHasTisInstalled(vehicle)) {
      vehicle?.tireInflationStatus === 'unstable' ? tisFaultsCount++ : tisNoFaultsCount++;
    }

    if (vehicle?.trailerBatteryStatus) {
      if (vehicle?.trailerBatteryStatus === TRAILER_BATTERY_STATUS.GOOD) batteryNoFaultsCount++;
      if (vehicle?.trailerBatteryStatus === TRAILER_BATTERY_STATUS.CAUTION) batteryAlertsCount++;
      if (vehicle?.trailerBatteryStatus === TRAILER_BATTERY_STATUS.CRITICAL) batteryFaultsCount++;
    }

    if (!vehicle?.trailerBatteryStatus && vehicle?.gatewayBatteryStatus) {
      if (vehicle?.gatewayBatteryStatus === TRAILER_BATTERY_STATUS.GOOD) batteryNoFaultsCount++;
      if (vehicle?.gatewayBatteryStatus === TRAILER_BATTERY_STATUS.CAUTION) batteryAlertsCount++;
      if (vehicle?.gatewayBatteryStatus === TRAILER_BATTERY_STATUS.CRITICAL) batteryFaultsCount++;
    }

    if (trailerHasLightFaultInstalled(vehicle) && vehicle?.lightFault) {
      lightsAlertsCount++;
    } else if (trailerHasLightFaultInstalled(vehicle)) lightsNoFaultsCount++;
  });

  const trailerFaultTotals = tisFaultsCount + absFaultsCount;
  const trailerAlertTotals = absAlertsCount;
  const trailerNoFaultTotals = trackingDashboardData.length - trailerFaultTotals - trailerAlertTotals;

  return {
    absNoFaultsCount,
    absFaultsCount,
    absAlertsCount,
    tisNoFaultsCount,
    tisFaultsCount,
    tisAlertsCount,
    trailerNoFaultTotals,
    trailerFaultTotals,
    trailerAlertTotals,
    batteryNoFaultsCount,
    batteryFaultsCount,
    batteryAlertsCount,
    lightsNoFaultsCount,
    lightsFaultsCount,
    lightsAlertsCount,
  };
};

export const toggleSensorDetailsScreen = (activeTab, goBack) => ({
  type: TOGGLE_SENSOR_DETAILS_SCREEN,
  activeTab,
  goBack,
});

export const openNavigationDrawer = trailer => ({
  type: OPEN_NAVIGATION_DRAWER,
  trailer,
});

export const closeNavigationDrawer = () => ({
  type: CLOSE_NAVIGATION_DRAWER,
});

export const filterTrucks = (selectedFilters, selectedCustomer, selectedVins) => ({
  type: FILTER_TRUCKS,
  selectedFilters,
  selectedCustomer,
  selectedVins,
});

//used on trailer management page
export const fetchAllFleetTrailers = () => async dispatch => {
  dispatch({ type: FETCH_ALL_FLEET_TRAILERS_BEGIN });

  try {
    const resp = await doQuery(fleetStatusApiQuery);
    handleErrors(resp);
    const json = await resp.json();

    if (json.errors && json.errors.length > 0) {
      dispatch({
        type: FETCH_ALL_FLEET_TRAILERS_FAIL,
        error: json.errors[0].message,
      });
    }

    const allFleetTrailers = json?.data?.fleetStatus?.trailers ?? [];
    const formattedFleetTrailers = allFleetTrailers.map(trailer => {
      const tireInflationStatus = trailer?.tis?.description ?? '';

      return {
        ...trailer,
        vinNumber: trailer?.vin ?? '',
        unitID: trailer?.unitId ?? '',
        locationData: trailer?.gps ?? {},
        trailerTisNotStable: tireInflationStatus === 'unstable',
        vehicleId: trailer?.vehicleId ?? '',
      };
    });

    dispatch({
      type: FETCH_ALL_FLEET_TRAILERS_SUCCESS,
      allFleetTrailers: formattedFleetTrailers,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ALL_FLEET_TRAILERS_FAIL,
      error: error.message,
    });
  }
};

//used on tracking page
export const fetchMapTrailersData = () => async dispatch => {
  dispatch({ type: FETCH_ALL_FLEET_TRAILERS_BEGIN });

  try {
    const resp = await doQuery(mapTrailersQuery);
    handleErrors(resp);
    const json = await resp.json();

    if (json.errors && json.errors.length > 0) {
      dispatch({
        type: FETCH_ALL_FLEET_TRAILERS_FAIL,
        error: json.errors[0].message,
      });
    }

    const allFleetTrailers = json?.data?.fleetStatusPage?.trailers ?? [];
    const formattedFleetTrailers = allFleetTrailers.map(
      ({
        vin,
        unitId,
        vehicleTypeId,
        customerId,
        installedSensorTypeIds,
        absFaultSeverityLevel,
        deviceTypeId,
        lat,
        lng,
        gpsHeading,
        loadStatus,
        tetheredStatus,
        timeUntetheredFormattedString,
        tireInflationStatus,
        absOdometer,
        gpsOdometer,
        connectivityStatus,
        gatewayBatteryStatus,
        trailerBatteryStatus,
        goProductType,
        baseProductType,
        isThermoking,
        lightFault,
      }) => ({
        vin,
        unitId,
        vinNumber: vin ?? '',
        unitID: unitId ?? '',
        vehicleTypeId,
        customerId,
        installedSensorTypeIdsList: installedSensorTypeIds?.length > 0 ? installedSensorTypeIds?.split(',').map(Number) : null,
        absSeverityLevelId: absFaultSeverityLevel,
        deviceTypeId,
        locationData: {
          lat,
          lng,
          basedHeading: gpsHeading,
        },
        isLoaded: loadStatus === 'loaded' ? true : loadStatus === 'unloaded' ? false : null,
        isTethered: tetheredStatus === 'tethered' ? true : tetheredStatus === 'untethered' ? false : null,
        timeUntetheredFormattedString,
        tireInflationStatus,
        absOdometerMiles: absOdometer,
        gpsOdometerMiles: gpsOdometer,
        connectivityStatus,
        gatewayBatteryStatus,
        trailerBatteryStatus,
        goProductType,
        baseProductType,
        isThermoking,
        lightFault,
      }),
    );
    const faultTotals = getFaultTotals(formattedFleetTrailers);
    dispatch({
      type: FETCH_ALL_FLEET_TRAILERS_SUCCESS,
      allFleetTrailers: formattedFleetTrailers,
    });
    dispatch(setFaultTotals(faultTotals));

    const fiilterCookies = Cookies.get(`trackingFilters-${window.localStorage.getItem('userName')}`);
    if (fiilterCookies) {
      dispatch(getFilterCookies(JSON.parse(fiilterCookies)));
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_FLEET_TRAILERS_FAIL,
      error: error.message,
    });
  }
};
